<template>
  <button
    class="sz-w-12 sz-h-12 sz-flex sz-items-center sz-self-end sz-justify-center sz-p-2 sz-space-x-3 sz-text-white sz-transition-all sz-rounded-full sz-outline-none sz-bg-primary sz-transition-100 sz-transition-ease-in sz-text-1.5xl active:sz-outline-none focus:sz-outline-none"
    @click="toggleChat"
  >
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="times"
      class="sz-w-8 sz-h-8 svg-inline--fa fa-times fa-w-10"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
      ></path>
    </svg>
  </button>
</template>

<script lang="ts">
export default {
  methods: {
    toggleChat() {
      this.$emit("toggleChat");
    },
  },
};
</script>
