import axios, { type AxiosInstance } from "axios";
import type ITenant from "./types/Tenant";

let safezoneApi: AxiosInstance;

/**
 * Gets all tenant information
 *
 * @return The tenant tag
 */
const getTenant = async (tenantTag: string) => {
  const response = await safezoneApi.get(`tenant/tenant/${tenantTag}/`);

  const result = response.data;

  return result as ITenant;
};

/**
 * initializeApi - Initializes the Axios API instance with provided configuration.
 *
 * This was added as we meed to pass runtime variables in main.ts to axios. This is the config object-
 *
 * Parameters:
 * - config: ImportMetaEnv | undefined - The configuration object containing environment variables,
 *   used for setting the base URL and other settings in the Axios instance.
 */
export function initializeApi(config: ImportMetaEnv | undefined) {
  safezoneApi = axios.create({
    baseURL: config?.VITE_API_URL,
  });
}

export { safezoneApi, getTenant };
