import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const files = import.meta.globEager("./locales/*.json");

  const messages: Record<string, any> = {};

  Object.keys(files).forEach((file) => {
    const matched = file.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = files[file];
    }
  });

  return messages;
}

const i18n = createI18n({
  legacy: false,
  locale: "de",
  fallbackLocale: "de",
  messages: loadLocaleMessages(),
});

export default i18n;
