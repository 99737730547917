<template>
  <div id="app" class="onboarding-widget" :style="popupStyle">
    <div
      v-if="isChatActive"
      class="sz-container sz-fixed sz-bottom-0 sz-right-0 sz-flex sz-flex-col sz-mb-12 sz-margin-right"
    >
      <OnboardingPopup
        v-if="isChatActive"
        :class="{ 'no-animate': noAnimate }"
        class="sz-mb-4 sz-bg-white"
        :language="language"
        :tenant="tenant"
        @close="toggleChat"
      />
      <OnboardingCloseButton @toggleChat="toggleChat" />
    </div>
    <OnboardingButton
      v-if="!isChatActive && floating"
      class="sz-fixed sz-bottom-0 sz-right-0 sz-mb-12 sz-margin-right"
      @toggleChat="toggleChat"
    />
  </div>
</template>

<script lang="ts" setup>
import i18n from "@/i18n";

import OnboardingPopup from "@/components/OnboardingPopup.vue";
import OnboardingButton from "@/components/OnboardingButton.vue";
import OnboardingCloseButton from "./components/OnboardingCloseButton.vue";

import {
  computed,
  ref,
  onMounted,
  getCurrentInstance,
  createApp,
  type ComponentInternalInstance,
} from "vue";
import { useOnboardingStore } from "./store/Onboarding";
import { app } from "./app";
import type { Ref } from "vue";
import { getTenant } from "./api";
import { addTenantMessagesToLocale } from "./tenant";

type instType = ComponentInternalInstance & { provides: any };

const inst: instType | null = getCurrentInstance() as instType;

// provide the app context to the component correctly
// for i18n to work
// https://rimdev.io/vue-3-custom-elements/
Object.assign(inst!.appContext, app._context);
Object.assign(inst!.provides, app._context.provides);

const onboardingStore = useOnboardingStore();

const floating = computed(() => {
  return (window as any).floating === "true";
});

const buttonId = computed(() => {
  return (window as any).buttonId;
});

const language = computed(() => {
  return (window as any).language;
});

const color = computed(() => {
  return (window as any).color;
});

const secondaryColor = computed(() => {
  return (window as any).secondaryColor;
});

const tenant = computed(() => {
  return (window as any).tenant;
});

const disabledColor = computed(() => {
  return (window as any).disabledColor;
});

const chatSecondaryColor = computed(() => {
  return (window as any).chatSecondaryColor;
});

const fontFamilySans = computed(() => {
  return (window as any).fontFamilySans;
});

const fontFamilySerif = computed(() => {
  return (window as any).fontFamilySerif;
});

const primaryHoverColor = computed(() => {
  return (window as any).primaryHoverColor;
});

const chatSecondaryTextColor = computed(() => {
  return (window as any).chatSecondaryTextColor;
});

const popupStyle = computed(() => {
  return Object.assign(
    {},
    color ? { "--color-primary": color.value } : null,
    secondaryColor ? { "--color-secondary": secondaryColor.value } : null,
    disabledColor ? { "--color-disabled": disabledColor.value } : null,
    chatSecondaryColor
      ? { "--color-chatsecondary": chatSecondaryColor.value }
      : null,
    chatSecondaryTextColor
      ? { "--color-chatsecondary-text": chatSecondaryTextColor.value }
      : null,
    fontFamilySans ? { "--font-family-sans": fontFamilySans.value } : null,
    fontFamilySerif ? { "--font-family-serif": fontFamilySerif.value } : null,

    primaryHoverColor
      ? { "--primary-hover-color": primaryHoverColor.value }
      : null
  );
});

const isChatActive = ref(false);
const noAnimate = ref(false);

onMounted(() => {
  loadTenantMessages();

  i18n.global.locale.value = language.value;

  if (!buttonId.value) {
    return;
  }
  const element = document.getElementById(buttonId.value);
  if (!element) {
    return;
  }
  element.addEventListener("click", () => {
    isChatActive.value = true;
  });
});

const loadTenantMessages = async () => {
  const tenantData = await getTenant(tenant.value);
  addTenantMessagesToLocale(tenantData);
};

const toggleChat = () => {
  isChatActive.value = !isChatActive.value;

  if (isChatActive.value) {
    noAnimate.value = true;

    setTimeout(() => {
      noAnimate.value = false;
    }, 1000);
  } else {
    onboardingStore.resetState();
  }
};
</script>

<!-- <style src="./assets/css/main.postcss" lang="postcss" /> -->

<style lang="postcss">
@import "@/assets/css/main.postcss";

.jonas {
  background-color: blue;
}

.no-animate {
  >>> * {
    animation: none;
  }
}

.sz-margin-right {
  @apply sz-mr-4;

  @apply md:sz-mr-12 !important;
}
</style>
