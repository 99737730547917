<template>
  <template v-for="child in children">
    <a v-if="'href' in child" :href="child.href">{{ child.value }}</a>
    <p v-else>
      {{ child.value }}
    </p>
  </template>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{ line: string | number }>();

const children = computed((): { href?: string; value: string }[] => {
  const children = [];
  let lastMatchEnd = 0;

  // Todo, maybe use a better url regex, this one is made up from my head
  const urlRegex =
    /https?:\/\/([a-zA-Z0-9.-]+(?:\/[a-zA-Z0-9.%:_()+=-]*)*(?:\?[a-zA-Z0-9.%:_+&/()=-]*)?(?:#[a-zA-Z0-9.%:()_+=-]*)?)/g;
  const line = props.line.toString(); // force string

  let match;

  // Loop through all links and create an anchor element for them
  /* eslint-disable */
  while ((match = urlRegex.exec(line))) {
    if (match.index - lastMatchEnd > 0) {
      children.push({ value: line.substring(lastMatchEnd, match.index) });
    }
    children.push({
      href: match[0],
      value: match[1],
    });
    lastMatchEnd = urlRegex.lastIndex;
  }
  if (lastMatchEnd < line.length) {
    children.push({
      value: line.substring(lastMatchEnd, line.length),
    });
  }

  return children;
});
</script>
