<template>
  <div
    class="sz-flex sz-items-center sz-justify-between sz-max-w-full action-item"
  >
    <div class="sz-pr-6">
      <input
        ref="input"
        v-model="value"
        v-focus
        data-test-counseling-action-input
        class="sz-block sz-w-full sz-p-2 sz-border-2 sz-border-gray-200 !sz-outline-primary !sz-ring-offset-0 !sz-ring-0 !sz-outline-offset-0 !sz-ring-offset-transparent focus:!sz-border-transparent"
        :placeholder="label"
        :type="type"
        @keypress.enter="send"
      />
    </div>
    <button
      class="sz-block sz-w-12 sz-h-12 sz-rounded-md btn--primary"
      :disabled="!isValid"
      data-test-counseling-action-button
      @click="send"
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fal"
        data-icon="paper-plane"
        class="sz-w-6 sz-h-6 sz-m-auto sz-fill-currentsvg-inline--fa fa-paper-plane fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M464 4.3L16 262.7C-7 276-4.7 309.9 19.8 320L160 378v102c0 30.2 37.8 43.3 56.7 20.3l60.7-73.8 126.4 52.2c19.1 7.9 40.7-4.2 43.8-24.7l64-417.1C515.7 10.2 487-9 464 4.3zM192 480v-88.8l54.5 22.5L192 480zm224-30.9l-206.2-85.2 199.5-235.8c4.8-5.6-2.9-13.2-8.5-8.4L145.5 337.3 32 290.5 480 32l-64 417.1z"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
/**
 * Default validator for the field, checks if it's a number if the field is
 * a number field, other wise if it's not empty
 */
const defaultValidator = (value: {}, type: string) => {
  if (type == "text") {
    return value !== "";
  }
  return /^\d+$/.test(value as string);
};
</script>

<script lang="ts" setup>
import { type Ref, ref, computed } from "vue";

const emit = defineEmits(["send"]);

const props = withDefaults(
  defineProps<{
    type?: string;
    label: string;
    inputValidator?: Function;
  }>(),
  {
    type: "text",
    inputValidator: defaultValidator,
  }
);

const value: Ref<string | number> = ref("");

const isValid = computed(() => {
  return props.inputValidator(value.value, props.type);
});

const send = () => {
  if (isValid) {
    emit("send", value.value);
  }
};
</script>
